var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: tooltip }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: { color: "secondary", icon: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.dialog = true
                          },
                        },
                      },
                      { ...tooltip }
                    ),
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("zoom_in"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [_c("span", [_vm._v("Show task outcome")])]
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c(
                  "div",
                  { staticClass: "secondary--text" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [
                      _vm._v("mdi-information-variant"),
                    ]),
                    _vm._v(" Task outcome "),
                  ],
                  1
                ),
              ]),
              _c("v-divider", { staticClass: "mb-1" }),
              _c(
                "v-card-text",
                [
                  _c("v-card-text", [
                    _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }